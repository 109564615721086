import React from "react";

const Header = () => {
  return (
    <header>
      <div class="contain">
        <a href="/" id="nameee">
          Josh Dluehosh
        </a>
        <nav>
          <a class="a2" href="/postit">
            Post Board
          </a>
          <a class="a2" href="/gpts">
            My GPTs
          </a>
          <a class="a2" href="/games">
            Games
          </a>

          <a
            class="a2"
            target="_blank"
            href="https://writewithme.joshdluehosh.com"
          >
            Write With Me
          </a>
          <a class="a2" target="_blank" href="https://chadgpt.joshdluehosh.com">
            chadGPT
          </a>
          <a class="a2" href="/contact">
            Contact / Donate
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
