import React, { useState } from "react";
import IMG_1046 from "../assets/images/IMG_1046.png";
import IMG_1047 from "../assets/images/IMG_1047.png";
import IMG_1048 from "../assets/images/IMG_1048.png";
import IMG_1049 from "../assets/images/IMG_1049.png";
import IMG_1050 from "../assets/images/IMG_1050.png";
import emailicon from "../assets/images/emailicon.png";

const copyToClipboard = (text, setActiveButton, buttonId) => {
  navigator.clipboard.writeText(text).then(() => {
    setActiveButton(buttonId);
  });
};

const CopyIcon = ({ active }) =>
  active ? (
    <span>Copied!</span>
  ) : (
    <svg viewBox="0 0 24 24">
      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
    </svg>
  );

const Contact = () => {
  const [activeButton, setActiveButton] = useState(null);

  const buttonsData = [
    {
      id: 1,
      text: "bc1q4hqt9yn28u84svjagmns2j5x25v44ly8xmnw02",
      name: "BTC",
      imgSrc: IMG_1046,
      imgAlt: "Bitcoin (BTC) Address",
    },
    {
      id: 2,
      text: "0x3d6F6BAa031a370B77DCC3bE78Bdd6A1742f2c06",
      name: "ETH",
      imgSrc: IMG_1047,
      imgAlt: "Ethereum (ETH) Address",
    },
    {
      id: 3,
      text: "9TtCMrjnVDKXqEUiZfg46Vq2T9LqHuZXM79xyg5gsR7F",
      name: "SOL",
      imgSrc: IMG_1048,
      imgAlt: "Solana (SOL) Address",
    },
    {
      id: 4,
      text: "LVUeumSepppUDHEepFhomfCph3CAEh3Abw",
      name: "LTC",
      imgSrc: IMG_1049,
      imgAlt: "Litecoin (LTC) Address",
    },
    {
      id: 5,
      text: "D9uPui5hWVUqEJkqys4QJoqXgTkCNrDR8s",
      name: "DOGE",
      imgSrc: IMG_1050,
      imgAlt: "Dogecoin (DOGE) Address",
    },
  ];

  return (
    <div>
      <div id="bod" className="contain">
        <article className="mainnn">
          <div className="center ph3-ns">
            <div className="cf ph2-ns">
              <h2 id="numb">Donations Desired</h2>
              <div className="flex-container">
                {buttonsData.map(({ id, text, name, imgSrc, imgAlt }) => (
                  <div key={id} className="pic-container">
                    <img className="pic" src={imgSrc} alt={imgAlt} />
                    <div className="pic-text">
                      <p>{name}</p>
                      <p className="hide-text">{text}</p>
                      <button
                        className={`copy-button ${
                          activeButton === id ? "active" : ""
                        }`}
                        onClick={() =>
                          copyToClipboard(text, setActiveButton, id)
                        }
                      >
                        <CopyIcon active={activeButton === id} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div id="email">
                <a href="mailto:joshdluehosh@gmail.com" target="_top">
                  <img id="ePic" src={emailicon} alt="Email Icon" />
                </a>
                <h4 id="numb">joshdluehosh@gmail.com</h4>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Contact;
