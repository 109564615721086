import React from "react";
import tower from "../assets/images/tower.png";

const gamesData = [
  {
    id: 1,
    name: "Tower Climber",
    description: "A fun, but challenging little rogue-like rpg",
    image: tower,
    link: "/attackontower",
  },
  /* {
    id: 2,
    name: "Ghost Writer",
    description: "Expert at expanding ideas into rich, long-form narratives.",
    image: ghostWrite,
    link: "https://chatgpt.com/g/g-GWOE4rMEn-ghost-writer",
  },
  {
    id: 3,
    name: "Storyboard Synthesizer",
    description:
      "Creates versatile, detailed visual narratives; seeks clarity or creatively interprets.",
    image: storySynth,
    link: "https://chatgpt.com/g/g-ogkNdSZyC-storyboard-synthesizer",
  },*/
];

const Games = () => {
  return (
    <div id="bod" className="contain">
      <div className="mainn">
        <h1>My Games</h1>
        <p id="p1">
          Test out my game demos!
          <p>More coming soon!</p>
        </p>
      </div>
      <div className="flex-container">
        {gamesData.map((game) => (
          <div key={game.id} className="gpt-container">
            <a href={game.link}>
              <div className="gpt-buttons">
                <img src={game.image} alt={game.name} className="gpt" />
                <div className="gpt-text">{game.name}</div>
                <div>{game.description}</div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Games;
